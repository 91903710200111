import React, { useRef, useState, useEffect } from "react";
import { useMyContext } from "@/context/MyContext";
import styles from "./styles.module.scss";
import ha_logo from "@/asset/ha_logo.png";
// === Deleted: Fix: Rule#S6478(Line#786) - 20240710 - By PennYao ===  
import wenben from "@/asset/wenben.png";
import reset from "@/asset/reset.png";
import tips from "@/asset/tips.png";
import search from "@/asset/search.png";
import logout from "@/asset/logout.png";
import user from "@/asset/user.png";
import { DatePicker, Input, message, Modal, Tooltip } from "antd";
// === Updated Start - 20240617 - By PennYao ===
import { getVoice, updateOtherRemark, updateRemark, updateScore, getHaData } from "@/apiConfig/index"; // "./mock";

import MyPageInput from '@/component/MyPageInput';
// === Updated End ===
import { useLoading } from "@/component/Loading";
import "./antd.css";
import cn from "classnames";
import MySelect from "./MySelect";
import MyAudio from "./MyAudio";
import dayjs from "dayjs";


const SearchPage = () => {
  const { token, username, onLogoff } = useMyContext();
  const onLoading = useLoading();
  const { TextArea } = Input;
  const { RangePicker } = DatePicker;
  const [patientCaseNo, setPatientCaseNo] = useState("");
  const [toPage, setToPage] = useState("");
  const [assessmentNo, setAssessmentNo] = useState("");
  const [dateRange, setDateRange] = useState(["", ""]);
  const [renderData, setRenderData] = useState({});
  const [dataKey, setDataKey] = useState(new Date());
  const voiceDataRef = useRef({});
  const [textareaLen, setTextareaLen] = useState({});
  const [textareaData, setTextareaData] = useState({});
  const [lastTextareaData, setLastTextareaData] = useState({});
  
  const [welcome, setWelcome] = useState(true);
  const [lastSearchData, setLastSearchData] = useState({"patientCaseNo": "", "assessmentNo": "", "dateRange": ["", ""]});
  const [nextPage, setNextPage] = useState(1);
  const [waitForSearch, setWaitForSearch] = useState(false);

  // === Updated：Fix Bug#20 - 20240813 - By PennYao === 

  useEffect(() => {
    // 当 value 更新后，这里的代码将被执行
    if (waitForSearch) {
      onSearch(nextPage);
      setWaitForSearch(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitForSearch, nextPage]); // 监听 value 的变化

  //翻页
  const onPageChange = (page = 1) => {
    if (lastSearchData.patientCaseNo !== patientCaseNo || lastSearchData.assessmentNo !== assessmentNo || lastSearchData.dateRange !== dateRange) {
      onResetForm(); // 重置表单
      setPatientCaseNo(lastSearchData.patientCaseNo);
      setAssessmentNo(lastSearchData.assessmentNo);
      setDateRange(lastSearchData.dateRange);
    }
    setNextPage(page); // 设置下一页
    setWaitForSearch(true); // 设置等待搜索
    // 调用 onSearch 函数
    // onSearch(page);
  };

  // === Updated：Fix Bug#20 - 20240813 - By PennYao === 

  const onChangeDate = (date, dateString) => {
    setDateRange(dateString);
  };

  //搜索数据
  const onSearch = async (page = 1) => {
    setLastSearchData({"patientCaseNo": patientCaseNo, "assessmentNo": assessmentNo, "dateRange": dateRange});
    try {
      const reg = /[,，]/;
      const patientCaseNos = patientCaseNo?.split(reg)?.map((x) => x?.trim());
      const assessmentNos = assessmentNo?.split(reg)?.map((x) => x?.trim());
      const startTime = dateRange[0] ? dateRange[0] : "";
      const endTime = dateRange[1] ? dateRange[1] : "";
      const params = {
        token,
        data: {
          ...(patientCaseNo && { patientCaseNos }),
          ...(assessmentNo && { assessmentNos }),
          ...(startTime && { startTime }),
          ...(endTime && { endTime }),
          page: page,
          rows: 3,
        },
      };
      // === Updated：Fix Rule#S125(Line#64) - 20240710 - By PennYao === 
      onLoading(true);
      const result = await getHaData(params);
      if (result.code === 200) {
        handleData(result?.data || {});
      } else {
        message.error("Sorry, failed to save, pls contact administrator.", 3);
      }
    } catch (e) {
      message.error("Sorry, failed to save, pls contact administrator.", 3);
    } finally {
      setWelcome(false);
      onLoading(false);
    }
  };

  //把数据处理成适合渲染的格式
  const handleData = (userData) => {
    const patientArr = []; //存放基本的数据
    const assesskObj = {}; //存放补充说明
    const remarkArr = []; //存放补充说明
    const { hasPreviousPage, hasNextPage, pages, total, pageNum } = userData;
    const list = userData?.list || [];
    const myObj = {};

    // === Updated Start: Fix: Rule#S3579(Line#106、Line#110) - 20240710 - By PennYao ===
    for (let i in list) {
      const ii = parseInt(i);
    // === Updated End: Fix: Rule#S3579(Line#106、Line#110) - 20240710 - By PennYao ===
      const item = list[ii];
      const {
        patientCaseNo,
        assessmentNo,
        educationLevel,
        assessmentDate,
        answerList,
        remarkList,
        otherRemarks,
        scoreResult,
      } = item;
      const patient = {
        patientCaseNo,
        educationLevel,
        assessmentNo,
        assessmentDate,
      };
      remarkArr[ii] = {
        otherRemarks,
        assessmentNo,
      };
      patientArr[ii] = patient;
      for (let x in answerList) {
        const msg = answerList[x];
        patientArr[ii].scoreTotal = scoreResult;
        msg.recordNum = ii; // patient Index
        // === Updated Start: Fix Bug#6 - 20240624 - By PennYao ===
        // const questionKey = msg.question;
        const questionKey = msg.question + msg.questionNo;
        // === Updated End: Fix Bug#6 - 20240624 - By PennYao ===

        if (!myObj[questionKey]) {
          myObj[questionKey] = {
            questionNo: msg.questionNo,
            question: msg.question,
            data: [],
            tempData: []
          };
        }
        myObj[questionKey].data.push(msg);
        myObj[questionKey].tempData.push(msg);
      }
      for (let y in remarkList) {
        const assess = remarkList[y];
        const remarkName = assess.remarkName;
        if (!assesskObj[remarkName]?.length > 0) {
          assesskObj[remarkName] = [];
        }
        assesskObj[remarkName].push(assess);
      }
    }
    const sortedValues = Object.values(myObj).sort(
      (a, b) => parseFloat(a.questionNo) - parseFloat(b.questionNo)
    );
    const questionRes = sortedValues.map((item) => {
      // console.log("item.data.length:", item.data.length, item.data);
      // === Updated Start: Feature Combine Answers - 20240619 - By PennYao ===
      // if (item.data.length >= 3) // 当 Patient 数量小于 3 个 
      if (item.data.length >= 1) 
      // === Updated End: Feature Combine Answers - 20240619 - By PennYao ===
      {
        return item;
      } 
      else 
      {
        const empytRow = [];
        patientArr.forEach(() => {
          empytRow.push({});
        });
        // 此处会导致 data 的长度小于 3 时，只显示 1 条数据
        item.data.forEach((x) => {
          empytRow[x.recordNum] = x;
          // === Updated Start：Fix Rule#S125(Line#161) - 20240710 - By PennYao === 
           
           
           
          // === Updated End：Fix Rule#S125(Line#161) - 20240710 - By PennYao === 
        });
        item.data = empytRow; 
        return item;
      }
    });
    setRenderData({
      patientArr,
      questionArr: questionRes,
      assesskObj,
      remarkArr,
      originData: list,
      pageData: {
        hasPreviousPage,
        hasNextPage,
        pages,
        total,
        pageNum,
      },
    });
  };

  //重置
  const onReset = () => {
    setRenderData({});
    onResetForm();
  };

  //重置表单
  const onResetForm= () => {
    setAssessmentNo("");
    setDateRange(["", ""]);
    setDataKey(new Date());
    setPatientCaseNo("");
  };

  //渲染页面顶部的三个客户列表
  const getInfoRow = () => {
    const list = renderData?.patientArr || [];
    return list.map((item, i) => {
      if (item.assessmentNo) {
        console.log(item.educationLevel);
        return (
          <div className={cn("patientWrap", "bg" + i)} key={item.assessmentNo}>
            <div className="patientInfo">
              <div className="patientBox">
                <div className="numText">
                  <div className="boldText">Patient Case No.:</div>
                  <div className="valueText">{item.patientCaseNo}</div>
                </div>
                <div className="numText">
                  <div className="boldText">Education Level:</div>
                  {/* === Updated Start: Fix: Changes#22 - 20241025 - By PennYao === */}
                  <div className="valueText">{item.educationLevel} {parseInt(item.educationLevel) >= 0 ? "year" : ""}{parseInt(item.educationLevel) === 0 || parseInt(item.educationLevel) > 1 ? "s" : ""}</div>
                  {/* === Updated End: Fix: Changes#22 - 20241025 - By PennYao === */}
                </div>
              </div>
              <div className="patientBox">
                <div className="numText">
                  <div className="boldText">Assessment No.:</div>
                  <div className="valueText">{item.assessmentNo}</div>
                </div>
                <div className="numText">
                  <div className="boldText">Assessment Date:</div>
                  <div className="valueText">{item.assessmentDate}</div>
                </div>
              </div>
            </div>
          </div>
        );
      }
      return null;
    });
  };

  //获取api或本地缓存的音频资源
  const onGetAudio = async (myVoice, id) => {
    try {
      if (!myVoice) {
        message.info("no record", 3);
        return;
      }
      let audioURL = "";
      const voiceData = voiceDataRef.current;
      if (voiceData?.[id]) {
        audioURL = voiceData[id];
      } else {
        onLoading(true);
        const res = await getVoice(myVoice, {
          responseType: "arraybuffer",
        });
        if (res.byteLength > 0) {
          const blob = new Blob([res], { type: "audio/mpeg" });
          audioURL = URL.createObjectURL(blob);
          voiceDataRef.current[id] = audioURL;
        } else {
          throw new Error();
        }
      }
      return audioURL;
    } catch (e) {
      // === Updated Start - 20240621 - By PennYao ===
      // message.error("download fail", 3);
      message.error("The file is corrupted and cannot be opened", 3);
      // === Updated End ===
    } finally {
      onLoading(false);
    }
  };

  //更新评分
  // === Updated Start Fix: API#13 - 20240724 - By PennYao ===
  const onUpdateScore = async (score, id, questionNo, assessmentNo) => {
    try {
      const reslut = await updateScore({
        token,
        data: {
          score,
          id,
          questionNo,
          assessmentNo
        },
      });
      // === Updated End Fix: API#13 - 20240724 - By PennYao ===
      if (reslut.code === 200) {
        setRenderData((state) => {
          const patientArr = state?.patientArr;
          for (let i in patientArr) {
            if (
              reslut?.scoreResult &&
              patientArr[i]?.assessmentNo === assessmentNo
            ) {
              patientArr[i].scoreTotal = reslut.scoreResult;
              break;
            }
          }
          return {
            ...state,
            patientArr,
          };
        });
        return true;
      } else {
        message.error("Sorry, failed to save, pls contact administrator.", 3);
        return false;
      }
    } catch (e) {
      message.error("Sorry, failed to save, pls contact administrator.", 3);
    }
  };

  //更新补充说明
  const onUpdateRemark = async (remarkResult, id) => {
    try {
      const result = await updateRemark({
        token,
        data: {
          remarkResult,
          id,
        },
      });
      if (result.code !== 200) {
        message.error("Sorry, failed to save, pls contact administrator.", 3);
        return false;
      }
      return true;
    } catch (e) {
      message.error("Sorry, failed to save, pls contact administrator.", 3);
    }
  };

  //重置文本框的更改的内容
  const resetTextarea = (assessmentNo) => {
    setTextareaData((state) => {
      return {
        ...state,
        [assessmentNo]: lastTextareaData[assessmentNo],
      };
    });
    const len = lastTextareaData[assessmentNo].length;
    setTextareaLen((state) => {
      return {
        ...state,
        [assessmentNo]: len,
      };
    });
  };

  //更新其他补充说明(文本框)
  const onUpdateOtherRemark = async (e, assessmentNo) => {
    try {
      const otherRemarks = e.target.value;
      const result = await updateOtherRemark({
        token,
        data: {
          otherRemarks,
          assessmentNo,
        },
      });
      if (result.code !== 200) {
        resetTextarea(assessmentNo);
        message.error("Sorry, failed to save, pls contact administrator.", 3);
      } else {
        setLastTextareaData((state) => {
          return {
            ...state,
            [assessmentNo]: otherRemarks,
          };
        });
      }
    } catch (e) {
      resetTextarea(assessmentNo);
      message.error("Sorry, failed to save, pls contact administrator.", 3);
    }
  };

  //确认文本框的更改内容
  const confirmTextInput = (e, assessmentNo) => {
    if (e.target.value === lastTextareaData[assessmentNo]) {
      return;
    }
    Modal.confirm({
      title: "Confirm",
      content: "Confirm to save the changes?",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: async () => {
        await onUpdateOtherRemark(e, assessmentNo);
      },
      onCancel: () => {
        resetTextarea(assessmentNo);
      },
      maskClosable: true,
    });
  };

  //当回答文本框的长度超过100时，显示提示信息
  const onShowModal = (answer) => {
    Modal.info({
      title: "Answer",
      width: 1000,
      maskClosable: true,
      content: <div className="answerWrap">{answer}</div>,
    });
  };

  // === Updated Start: Fix: Rule#S3776(Line#401) - 20240710 - By PennYao ===
  // 渲染页面问题列表表头
  const getQuestionHeader = (patientArr) => {  
    return (
      <div className={cn("row", "headBar")}>
        <div className="middleCell">
          <div className="cellDiv">No.</div>
        </div>
        <div className="bigCell">
          <div className="cellDiv">Question</div>
        </div>
        {patientArr?.map((p, i) => {
          return (
            <div className={cn("cells", "bg" + i)} key={p.assessmentNo}>
              <div className="cell">
                <div className="cellDiv">Score</div>
              </div>
              <div className="cell">
                <div className="cellDiv">Text</div>
              </div>
              <div className={cn("cell", "cell2")}>
                <div className="cellDiv">Audio</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  // 渲染页面问题列表总结
  const getQuestionSummary = (patientArr) => {
    return (
      <div className={cn("row", "headBar")}>
          <div className={cn("middleCell", "noBorderRight")}>
            <div className="cellDiv">Total</div>
          </div>
          <div className="bigCell">
            <div className="cellDiv"></div>
          </div>
          {patientArr?.map((x, i) => {
            return (
              <div className="cells" key={x.assessmentNo}>
                <div className={cn("cell", "noBorderRight")}>
                  <div className="cellDiv">{x.scoreTotal}</div>
                </div>
                <div className={cn("cell", "noBorderRight")}>
                  <div className="cellDiv"></div>
                </div>
                <div className={cn("cell", "cell2")}>
                  <div className="cellDiv"></div>
                </div>
              </div>
            );
          })}
        </div>
    );
  };

  // 渲染页面问题列表分数
  const getQuestionScore = (item, p, n) => {  
    return (
      <>
      {
        item?.data?.map((x, i) => {
          if (n === 0) {
            if (x.assessmentNo === p.assessmentNo && x.questionNo === item.questionNo) {
              n++;
              return (
                <div className="cell" key={x.id}>
                  {
                    x.questionNo === "3" ? (
                      <Tooltip
                        placement="bottom"
                        trigger="hover"
                        title="Not calculate in total score"
                      >
                        <div className="cellDiv">
                          <MySelect data={x} onUpdate={onUpdateScore} />
                        </div>
                      </Tooltip>
                    ) : (
                      <div className="cellDiv">
                        <MySelect data={x} onUpdate={onUpdateScore} />
                      </div>
                    )
                  }
                </div>
              );
            }
            else {
              return null;
            }
          }
          else {
            return null;
          }
        })
      }
      {
        n === 0 ? (
          <div className="cell"></div>
        ) : null
      }
      </>
    );
  };

  // 渲染页面问题列表文本
  const getQuestionText = (item, p) => {
    return (
      <div className="cell" style={{ flexDirection: 'column' }}>
        {
          item?.data?.map((x, i) => {
            if (x.assessmentNo === p.assessmentNo && x.questionNo === item.questionNo) {
              return (
                <div className="cellDiv" key={x.id}>
                  {
                    x?.answer?.length > 100 ? (
                      <img
                        onClick={() => onShowModal(x.answer)}
                        className="wenben"
                        src={wenben}
                        alt="text"
                      />
                    ) : (
                      <Tooltip
                        placement="top"
                        trigger="click"
                        title={x.answer}
                      >
                        <img className="wenben" src={wenben} alt="text" />
                      </Tooltip>
                    )
                  }
                </div>
              );
            }
            else {
              return (
                <div className="cellDiv" key={x.id}></div>
              );
            }
          })
        }
      </div>
    );
  };

  // 渲染页面问题列表语音
  const getQuestionAudio = (item, p) => {
    return (
      <div className={cn("cell", "cell2")} style={{ flexDirection: 'column' }}>
        {
          item?.data?.map((x, i) => {
            if (x.assessmentNo === p.assessmentNo && x.questionNo === item.questionNo) {
              return (
                <div className="cellDiv" key={x.id}>
                  <MyAudio
                    onGetAudio={() => onGetAudio(x.audioUrl, x.id)}
                  />
                </div>
              );
            }
            else {
              return (
                <div className="cellDiv" key={x.id}></div>
              );
            }
          })
        }
      </div>
    );
  };
  // === Updated End: Fix: Rule#S3776(Line#401) - 20240710 - By PennYao ===

  //渲染页面问题列表
  const getQuestionRow = () => {
    const list = renderData?.questionArr;
    const patientArr = renderData?.patientArr;

    return (
      <>
        {getQuestionHeader(patientArr)}
        {
          list.map((item, k) => {
            return (
              <div className={cn("row", "hoverRow")} key={
                // === Updated Start: Fix: Bug#12(PPT#39) - 20240626 - By PennYao ===
                // item.questionNo
                item?.question + item?.questionNo
                // === Updated End: Fix: Bug#12(PPT#39) - 20240626 - By PennYao ===
              }>
                <div className="middleCell">
                  <div className="cellDiv">{item?.questionNo || ""}</div>
                </div>
                <div className="bigCell">
                  <div className="cellDiv">{item?.question || ""}</div>
                </div>
                {/* === Added Start: Feature Combine Answers - 20240617 - By PennYao === */}
                {
                  patientArr?.map((p, j) => {
                    let n = 0;
                    return (
                      <div className={cn("cells", "bgCell" + j)} key={p.assessmentNo}>
                        {getQuestionScore(item, p, n)}
                        {getQuestionText(item, p)}
                        {getQuestionAudio(item, p)}
                      </div>  
                    );
                  })
                }
                {/* === Added End: Feature Combine Answers === */}
                {/* === Deleted Start: Feature Combine Answers - 20240617 - By PennYao === */}
                {/* === Updated Start：Fix: Rule#S125(Line#553) - 20240710 - By PennYao === */}
                






























































                {/* === Updated End：Fix: Rule#S125(Line#553) - 20240710 - By PennYao === */}  
                {/* === Deleted Start: Combine Answers Answer */}
              </div>
            );
          })}
        {getQuestionSummary(patientArr)}
      </>
    );
  };

  //渲染页面补充说明列表
  const getAssessmentRow = () => {
    const list = renderData?.assesskObj;
    const keys = Object.keys(list);
    const len = keys.length;
    return (
      <>
        {keys.map((key, index) => {
          const item = list[key];
          return (
            <div className={cn("row", "hoverRow2")} key={item[0]?.remarkName}>
              <div
                className={cn("bigCell", index + 1 === len && "noBorderBottom")}
              >
                <div className="cellDiv">{item[0]?.remarkName}</div>
              </div>
              {item.map((x, i) => {
                return (
                  <div
                    className={cn(
                      "cell",
                      "bgCell" + i,
                      index + 1 === len && "noBorderBottom"
                    )}
                    key={x.id}
                  >
                    <div className="cellDiv">
                      <MySelect data={x} isRemark onUpdate={onUpdateRemark} />
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </>
    );
  };

  //文本框的输入
  const onInputTextarea = (e, assessmentNo) => {
    const len = e.target.value.length;
    setTextareaLen((state) => {
      return {
        ...state,
        [assessmentNo]: len,
      };
    });
    setTextareaData((state) => {
      return {
        ...state,
        [assessmentNo]: e.target.value,
      };
    });
    return false;
  };

  //备份文本框原本内容
  const onSaveText = (e, assessmentNo) => {
    setLastTextareaData((state) => {
      return {
        ...state,
        [assessmentNo]: e.target.value,
      };
    });
  };

  //渲染页面的文本框
  const getRemarkRow = () => {
    const list = renderData?.remarkArr;
    return (
      <>
        <div className={cn("row")}>
          <div className={cn("bigCell", "noBorderBottom")}></div>
          {list.map((item, i) => {
            return (
              <div
                className={cn("textCell", "bgCell" + i, "textAreaWrap")}
                key={item.assessmentNo}
              >
                <div className={cn("cellDiv", "remarkText", "textAreaPad")}>
                  <div className="inputWrap">
                    <TextArea
                      type="textarea"
                      value={
                        /* === Updated Start: Fix: API#14 - 20240801 - By PennYao === */
                        
                        textareaData[item.assessmentNo] !== undefined ? textareaData[item.assessmentNo] : item.otherRemarks
                        /* === Updated End: Fix: API#14 - 20240801 - By PennYao === */
                      }
                      maxLength={1000}
                      autoSize
                      onChange={(e) => onInputTextarea(e, item.assessmentNo)}
                      onFocus={(e) => onSaveText(e, item.assessmentNo)}
                      onBlur={(e) => confirmTextInput(e, item.assessmentNo)}
                      className="myInput"
                    />
                    <div className="alreadyInput">
                      {/* === Updated Start: Fix: API#14 - 20240801 - By PennYao === */}  
                      
                      {textareaLen[item.assessmentNo] !== undefined ? textareaLen[item.assessmentNo] : item.otherRemarks?.length}
                      /1000 chars
                      {/* === Updated End: Fix: API#14 - 20240801 - By PennYao === */}  
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const pagesInfo = renderData?.pageData || {};

  //跳转到指定页
  const onToPage = () => {
    let initPage = toPage;
    if (+toPage > pagesInfo.pages) {
      initPage = pagesInfo.pages;
    } else if (+toPage <= 0) {
      initPage = 1;
    }
    onPageChange(initPage);
    setToPage("");
  };

  //登出modal
  const confirm = () => {
    Modal.confirm({
      title: "Confirm",
      content: "Confirm to log off?",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: onLogoff,
      maskClosable: true,
    });
  };

  // === Deleted: Fix: Rule#S6478(Line#786) - 20240710 - By PennYao ===   

  return (
    <div className={styles.root}>
      <div className="topBar">
        This platform is only for proof-of-concept trials, does not guarantee the retention of uploaded data, and should not be used for any sensitive or confidential information.
      </div>
      <div className="header">
        <img src={ha_logo} alt="logo" />
        <div className="userName">
          <div className="userNameText">
            <img src={user} alt="user" />
            {username}
          </div>
          <div className="logout" onClick={confirm}>
            <img src={logout} alt="logout" />
            Logout
          </div>
        </div>
      </div>
      <div className="contentBox">
        <div className="searchBar">
          <div className="inputBox">
            <div>Patient Case No.：</div>
            <div className="inputWrap">
              <Input
                value={patientCaseNo}
                onChange={(e) => setPatientCaseNo(e.target.value)}
                maxLength={255}
                className="myInput"
                allowClear
                onKeyUp={(k) => {
                  if (k.code === "Enter") {
                    // === Updated Start: Feature: Use enter to start the search, instead of typing a comma - 20241017 - By PennYao ===
                    // setPatientCaseNo(patientCaseNo + ",");
                    onSearch();
                    // === Updated End: Feature: Use enter to start the search, instead of typing a comma - 20241017 - By PennYao ===
                  }
                }}
              />
            </div>
            <Tooltip
              placement="top"
              trigger="click"
              title='If you want to inquire about more than one patient case, please use a comma (",") to separate them. For example: "patien001, patien002, patien003".'
            >
              <img src={tips} alt="tips" />
            </Tooltip>
          </div>
          <div className="inputBox">
            <div>Assessment No.：</div>
            <div className="inputWrap">
              <Input
                allowClear
                value={assessmentNo}
                maxLength={255}
                onChange={(e) => setAssessmentNo(e.target.value)}
                className="myInput"
                onKeyUp={(k) => {
                  if (k.code === "Enter") {
                    // === Updated Start: Feature: Use enter to start the search, instead of typing a comma - 20241017 - By PennYao ===
                    // setAssessmentNo(assessmentNo + ",");
                    onSearch();
                    // === Updated End: Feature: Use enter to start the search, instead of typing a comma - 20241017 - By PennYao ===
                  }
                }}
              />
            </div>
            <Tooltip
              placement="top"
              trigger="click"
              title='If you want to inquire about more than one assessment, please use a comma (",") to separate them. For example: "pre-amt-001, pre-amt-002, pre-amt-003".'
            >
              <img src={tips} alt="tips" />
            </Tooltip>
          </div>
          <div className="inputBox">
            <div>Assessment Date：</div>
            <div className="pickerWrap">
              <RangePicker
                key={dataKey}
                // === Updated Start: Feature: Combine Answers - 20240701 - By PennYao ===
                // format="MM/DD/YYYY HH:mm:ss"
                format="DD/MM/YYYY HH:mm:ss"
                // === Updated End: Feature: Combine Answers - 20240701 - By PennYao ===
                showTime={{
                  defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')],
                }}
                onChange={onChangeDate}
                placeholder={["From", "End"]}
              />
            </div>
          </div>
          <div className="btnBox">
            <div className="searchImg">
              <img src={search} onClick={() => onSearch()} alt="search" />
            </div>
            <div className="resetImg">
              <img src={reset} onClick={onReset} alt="reset" />
            </div>
          </div>
        </div>
        {renderData?.patientArr?.length > 0 ? (
          <div className="myTable">
            {/* === Updated Start: Fix: Rule#S6478(Line#786) - 20240710 - By PennYao === */}    
            <MyPageInput myClass="barBottom" pagesInfo={pagesInfo} toPage={toPage} setToPage={setToPage} onToPage={onToPage} onSearch={onPageChange} />
            {/* === Updated End: Fix: Rule#S6478(Line#786) - 20240710 - By PennYao === */}    
            <div className="patientList">
              <div className="flex7">
                <div className="title">Question List</div>
              </div>
              <div className="flex9">{getInfoRow()}</div>
            </div>
            <div className="questionList">
              <div
                className={cn(
                  "questionTable",
                  renderData?.patientArr.length === 3 && "noBorderRight"
                )}
              >
                {getQuestionRow()}
              </div>
              <div className="title">Remark</div>
              <div
                className={cn(
                  "remarkList",
                  renderData?.patientArr.length === 3 && "noBorderRight"
                )}
              >
                <div className="head">
                  <div>During assessment, does patient have</div>
                </div>
                <div className="assessRight">{getAssessmentRow()}</div>
              </div>
              <div
                className={cn(
                  "remarkList",
                  "noBorderTop",
                  renderData?.patientArr.length === 3 && "noBorderRight"
                )}
              >
                <div className={cn("head", "borderTop")}>
                  <div>Other Remark</div>
                </div>
                <div className="assessRight">{getRemarkRow()}</div>
              </div>
            </div>
            {/* === Updated Start: Fix: Rule#S6478(Line#786) - 20240710 - By PennYao === */}         
            <MyPageInput myClass="barTop" pagesInfo={pagesInfo} toPage={toPage} setToPage={setToPage} onToPage={onToPage} onSearch={onPageChange} />
            {/* === Updated End: Fix: Rule#S6478(Line#786) - 20240710 - By PennYao === */}
          </div>
        ) : (
          <div className="myTable">
            <div>
              {/* === Updated Start: Fix: Bug#21 - 20240812 - By PennYao === */}         
              {
                welcome ? 
                <p className="welcome">Welcome! To get started, please enter your search criteria above and click on the search button.</p> : 
                "The given search criteria returned no records in the system. Please try again."
              }
              {/* === Updated End: Fix: Bug#21 - 20240812 - By PennYao === */}  
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchPage;